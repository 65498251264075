module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"sericaia-www-personal-page","short_name":"sericaia-www","start_url":"/","background_color":"#E70567","theme_color":"#E70567","display":"minimal-ui","icon":"src/images/sericaia.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3c33cc742e9b88395a98dceda668116b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-prismjs","id":"b8d3a2f5-5540-5699-b862-153e57b2c618","name":"gatsby-remark-prismjs","version":"3.5.13","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.3.30","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-copy-linked-files","id":"80533a5b-ad2d-50ff-af51-726674869458","name":"gatsby-remark-copy-linked-files","version":"2.3.16","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs","options":{"showLineNumbers":true,"aliases":{"js":"jsx"}}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"withWebp":true,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"assets"}}],"defaultLayouts":{"default":"/opt/build/repo/src/components/Layout/index.js"}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
